import { BellFill, DoorOpenFill } from '@styled-icons/bootstrap';
import { clearToken } from '../../api/authenticationContext';
import { Collapse, Nav, NavItem } from '@bootstrap-styled/v4';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const NavLink = styled(Link)`
  display: block;
  padding: 2rem 1.5em;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  text-decoration: none;
  color: #fff;

  &:hover {
    color: #f0f0f0;
  }
`;

function HeaderLinks({ isOpen }) {
  const navigate = useNavigate();
  const logoutClickHandler = (e) => {
    e.preventDefault();
    clearToken();
    clearToken();
    navigate('/login');
  };

  return (
    <Collapse navbar isOpen={isOpen}>
      <Nav navbar className="ml-auto">
        <NavItem>
          <NavLink to="/my-courses">My Courses</NavLink>
        </NavItem>
        <NavItem>
          <NavLink to="/help">Help</NavLink>
        </NavItem>
        <NavItem>
          <NavLink to="/logout" onClick={logoutClickHandler}>
            <DoorOpenFill size={18} />
          </NavLink>
        </NavItem>
      </Nav>
    </Collapse>
  );
}

export default HeaderLinks;
