import { authenticateUser } from '../api/users';
import { BoxedPrimaryButton, BoxedSecondaryButton } from '../components/base/Buttons';
import { Form, FormActions, FormInput, FormLabel } from '../components/base/Form';
import { H3 } from '../components/base/Headers';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import Loader from '../components/Loader';
import SplashChrome from '../components/SplashChrome';
import styled from 'styled-components';
import useFormInput from '../hooks/useFormInput';

const LoginButtonContainer = styled(FormActions)`
  margin-bottom: 2rem;

  @media screen and (max-width: 1024px) {
    margin-bottom: 0;
  }
`;

const CreateAccountButtonContainer = styled(FormActions)`
  margin-top: 2rem;

  @media screen and (max-width: 1024px) {
    margin-top: 0;
  }
`;

function RegisterButton() {
  const navigate = useNavigate();
  const createAccountClickHandler = (e) => {
    e.preventDefault();
    navigate('/register');
  };

  return (
    <>
      <FormLabel>&nbsp;</FormLabel>
      <H3>- or -</H3>
      <CreateAccountButtonContainer>
        <BoxedSecondaryButton onClick={createAccountClickHandler} type="button">
          Create An Account
        </BoxedSecondaryButton>
      </CreateAccountButtonContainer>
    </>
  );
}

function LoginButtons({ isSubmitting }) {
  if (isSubmitting) {
    return (
      <FormActions>
        <Loader />
      </FormActions>
    );
  }

  return (
    <>
      <LoginButtonContainer>
        <BoxedPrimaryButton>Login</BoxedPrimaryButton>
        {/* <Link to="/forgot-password">I Forgot My Password</Link> */}
      </LoginButtonContainer>
      {/* <RegisterButton /> */}
    </>
  );
}

function LoginForm({ onSubmit, isSubmitting }) {
  const emailInput = useFormInput('');
  const passwordInput = useFormInput('');

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      email: emailInput.value,
      password: passwordInput.value,
    };

    onSubmit(data);
  };

  const sidebar = {
    title: __KA_SITE_TITLE__,
    description: __KA_SITE_DESCRIPTION__,
  };

  return (
    <>
      <SplashChrome title="Login" sidebar={sidebar}>
        <Form onSubmit={handleSubmit}>
          <FormLabel>&nbsp;</FormLabel>
          <H3>Login to your account</H3>
          <FormLabel htmlFor="title" required>
            <span>Email</span>
          </FormLabel>
          <FormInput id="email" maxLength="255" {...emailInput} />

          <FormLabel htmlFor="password" required>
            <span>Password</span>
          </FormLabel>
          <FormInput
            id="password"
            type="password"
            autoComplete="current-password"
            maxLength="255"
            {...passwordInput}
          />
          <LoginButtons isSubmitting={isSubmitting} />
        </Form>
      </SplashChrome>
    </>
  );
}

function LoginScreen() {
  /* -- Hooks -- */
  const navigate = useNavigate();
  const [authenticate, { isLoading: isSubmitting }] = useMutation(authenticateUser);

  const handleLogin = (data) => {
    authenticate(data, {
      onSuccess: () => {
        navigate(`/my-courses`);
      },
    });
  };

  return <LoginForm onSubmit={handleLogin} isSubmitting={isSubmitting} />;
}

export default LoginScreen;
