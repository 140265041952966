const moment = require('moment');

/* This fires whenever we load data from the API */
export default (response) => {
  const course = {
    ...response,
  };

  if (!course.thumbnail) {
    course.thumbnail = course.image.replace('.png', '-thumb.jpg');
  }

  if (!course.key) {
    course.key = `${course.slug}/${course.courseOfferingId}`;
  }

  const now = moment();
  let nextUnit = null;
  let previousUnit = null;
  let nrUnitsCompleted = 0;
  course.units.forEach((unit) => {
    if (!unit.thumbnail) {
      unit.thumbnail = `https://sok-assets.s3.amazonaws.com/${unit.slug}.png`;
    }

    if (!unit.key) {
      unit.key = unit.slug;
    }

    if (unit.started) {
      course.showResume = true;
    }

    if (!unit.complete) {
      course.complete = false;
    }

    if (!nextUnit || nextUnit.percentage < unit.percentage) {
      nextUnit = unit;
    }

    unit.isReleased = true;

    if (unit.releaseDate && now.isBefore(moment(unit.releaseDate))) {
      unit.isReleased = false;
    }

    let nextLesson = null;
    let nrCompleted = 0;
    unit.lessons.forEach((lesson, i) => {
      lesson.isBonusContent = lesson.title.indexOf('Bonus') !== -1;
      if (!lesson.thumbnail) {
        const path = lesson.isBonusContent ? `week_bonus` : `week${i + 1}`;
        lesson.thumbnail = `https://sok-assets.s3.amazonaws.com/${path}.png`;
      }

      if (!lesson.key) {
        lesson.key = lesson.id;
      }

      if (lesson.sections && lesson.sections.length !== 0) {
        const section = lesson.sections[0];
        lesson.content = {
          ...section,
          isTextOnly: !section.media,
          isVideo: !!section.media,
        };

        if (section.complete) {
          lesson.sectionsComplete = true;
        }

        if (
          lesson.assignment &&
          lesson.assignment.questions &&
          lesson.assignment.questions.length === 0
        ) {
          lesson.assignment = null;
        }
      }

      lesson.mode = 'default';

      if (!lesson.resources) {
        lesson.resources = [];
      }

      if (lesson.complete) {
        nrCompleted++;
      }

      if (!nextLesson && !lesson.complete) {
        nextLesson = lesson;
      }

      lesson.isAvailable = lesson.isBonusContent;
      if (lesson.releaseDate && now.isBefore(moment(lesson.releaseDate))) {
        lesson.isAvailable = false;
      }

      if (!lesson.releaseDate || (lesson.releaseDate && now.isAfter(moment(lesson.releaseDate)))) {
        lesson.isAvailable = true;
      }

      if (
        !lesson.url &&
        lesson.sections &&
        lesson.sections.length &&
        lesson.sections[0].media &&
        lesson.sections[0].media.externalUrl
      ) {
        lesson.url = lesson.sections[0].media.externalUrl;
      }

      lesson.done = lesson.complete;
    });

    unit.nextLesson = nextLesson;
    unit.percentage = nrCompleted === 0 ? 0 : (nrCompleted / unit.lessons.length) * 100;
    unit.complete = nrCompleted === unit.lessons.length;

    unit.disabled = previousUnit && !previousUnit.complete;

    previousUnit = unit;

    if (unit.complete) {
      nrUnitsCompleted++;
    }
  });

  course.nextUnit = nextUnit;
  course.complete = nrUnitsCompleted === course.units.length;

  return course;
};
