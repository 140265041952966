import { Col, Container, Row } from '@bootstrap-styled/v4';
import { Helmet } from 'react-helmet';
import buildTitle from '../utils/buildTitle';
import Footer from './Footer';
import Header from './header/Header';
import localize from '../utils/localize';

function Chrome({ breadcrumbs, children, title }) {
  return (
    <>
      <Helmet>
        <title>{buildTitle(localize(title))}</title>
      </Helmet>
      <Header breadcrumbs={breadcrumbs} logo />
      <Container>
        <Row>
          <Col md={12}>{children}</Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}

export default Chrome;
