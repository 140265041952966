import { BoxedSecondaryButton, BoxedTertiaryButton } from '../components/base/Buttons';
import { Col, Container, Row } from '@bootstrap-styled/v4';
import { H3, H4 } from '../components/base/Headers';
import { useNavigate } from 'react-router';
import SplashChrome from '../components/SplashChrome';
import styled from 'styled-components';

const FluidImage = styled.img`
  max-width: 100%;
  height: auto;
`;

const PageContainer = styled(Container)`
  margin-bottom: 15rem;
  margin-top: -5rem;
`;

const ContentContainer = styled.div`
  padding: 3rem;
`;

const DownloadIOSButton = styled.a`
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 10px !important;
  color: #fff;
  background-color: #000;
  line-height: 1.4;
  padding: 0.5714285714rem 1.4285714286rem;
  font-size: 1.0714285714rem;
  position: relative;
  transition: 0.2s ease;
  cursor: pointer;
  margin-bottom: 1rem;
`;

const DownloadAndroidButton = styled.a`
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 10px !important;
  color: #fff;
  background-color: #333;
  line-height: 1.4;
  padding: 0.5714285714rem 1.4285714286rem;
  font-size: 1.0714285714rem;
  position: relative;
  transition: 0.2s ease;
  cursor: pointer;
`;

const MobileHeaderContainer = styled.div`
  display: none;

  @media screen and (max-width: 968px) {
    display: block;
    margin-bottom: 1rem;
  }
`;

const MobileHeader = styled(H3)`
  font-weight: bold;
`;

function AboutScreen() {
  /* -- Hooks -- */
  const sidebar = {
    title: __KA_SITE_TITLE__,
    description: __KA_SITE_DESCRIPTION__,
  };

  return (
    <>
      <SplashChrome title="About" sidebar={sidebar}>
        <PageContainer>
          <Row>
            <Col md={8}>
              <ContentContainer>
                <MobileHeaderContainer>
                  <MobileHeader>School Of Kingdom</MobileHeader>
                </MobileHeaderContainer>
                <H3>KINGDOM REVELATION</H3>
                <H4>TO CHANGE THE WORLD</H4>
                <Row style={{ paddingTop: '2rem' }}>
                  <Col md={6}>
                    <DownloadIOSButton href="https://apps.apple.com/us/app/school-of-kingdom/id1514333033">
                      <span className="float-right g-font-size-13">
                        Download App
                        <span className="d-block g-font-size-10 g-font-weight-400 g-opacity-0_6">
                          From App Store
                        </span>
                      </span>
                    </DownloadIOSButton>
                  </Col>
                  <Col md={6}>
                    <DownloadAndroidButton href="https://play.google.com/store/apps/details?id=com.schoolofkingdom">
                      <span className="float-right g-font-size-13">
                        Download App
                        <span className="d-block g-font-size-10 g-font-weight-400 g-opacity-0_6">
                          From Play Market
                        </span>
                      </span>
                    </DownloadAndroidButton>
                  </Col>
                </Row>
              </ContentContainer>
            </Col>
            <Col md={4}>
              <FluidImage src="/images/app-in-hand.png" alt="App In Hand" />
            </Col>
          </Row>
        </PageContainer>
      </SplashChrome>
    </>
  );
}

export default AboutScreen;
