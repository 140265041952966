import './SlideButton.scss';
import IconArrowDown from './icons/IconArrowDown';
import React from 'react';

const SlideButton = ({ onClick, type }) => (
  <button type="button" className={`slide-button slide-button--${type}`} onClick={onClick}>
    <span>
      <IconArrowDown />
    </span>
  </button>
);

export default SlideButton;
