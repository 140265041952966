import { Col, Container, Row } from '@bootstrap-styled/v4';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 60px;
  z-index: 999;
  background-color: #000;
  padding-top: 1rem;
  padding-bottom: 2rem;
`;

const FooterCol = styled(Col)`
  text-align: left;
`;

const FlexContainer = styled.div`
  display: flex;
`;

const Copyright = styled.small`
  display: block;
  font-family: var(--font-family--body);
  color: rgba(255, 255, 255, 0.8);
  margin-right: 0.5rem;
`;

const LinkList = styled.ul`
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  margin-top: -0.3rem;
`;

const LinkListItem = styled.li`
  display: inline-block;
  margin-right: 0.5rem;
`;

const FooterLink = styled(Link)`
  color: rgba(255, 255, 255, 0.8);
  font-size: 12px;

  &:hover {
    color: #fff;
  }
`;

function Footer() {
  return (
    <FooterContainer>
      <Container>
        <Row>
          <FooterCol md={12}>
            <FlexContainer>
              <Copyright>2020 - 2021 &copy; All Rights Reserved.</Copyright>
              <LinkList>
                <LinkListItem>
                  <FooterLink to="/about">About</FooterLink>
                </LinkListItem>
                <LinkListItem>
                  <span>|</span>
                </LinkListItem>
                <LinkListItem>
                  <FooterLink to="/privacy">Privacy Policy</FooterLink>
                </LinkListItem>
                <LinkListItem>
                  <span>|</span>
                </LinkListItem>
                <LinkListItem>
                  <FooterLink to="/terms">Terms of Use</FooterLink>
                </LinkListItem>
                <LinkListItem>
                  <span>|</span>
                </LinkListItem>
                <LinkListItem>
                  <FooterLink to="/help">Support</FooterLink>
                </LinkListItem>
              </LinkList>
            </FlexContainer>
          </FooterCol>
        </Row>
      </Container>
    </FooterContainer>
  );
}

export default Footer;
