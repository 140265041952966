import './Item.scss';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import Mark from './Mark';
import React from 'react';
import ShowDetailsButton from './ShowDetailsButton';
import SliderContext from './context';

const Item = ({ movie }) => (
  <SliderContext.Consumer>
    {({ onSelectSlide, currentSlide, elementRef }) => {
      const isActive = currentSlide && currentSlide.id === movie.id;

      return (
        <div
          ref={elementRef}
          className={cx('item', {
            'item--open': isActive,
          })}
        >
          <Link to={`/my-courses/library/${movie.id}`}>
            <img src={movie.image} alt="" />
          </Link>
          <ShowDetailsButton onClick={() => onSelectSlide(movie)} />
          {isActive && <Mark />}
        </div>
      );
    }}
  </SliderContext.Consumer>
);

export default Item;
