import { setToken } from './authenticationContext';
import request from './request';

async function authenticateUser({ email, password }) {
  const { token } = await request
    .post(`login`, {
      json: {
        email,
        password,
      },
      prefixUrl: __KA_API_URL__,
    })
    .json();

  setToken(token);
}

async function requestPasswordResetToken({ email }) {
  return request
    .put(`reset-password`, {
      json: {
        email,
      },
      prefixUrl: __KA_API_URL__,
    })
    .json();
}

async function resetPassword({ password, token }) {
  return request
    .post(`password-reset/${token}`, {
      json: {
        password,
      },
      prefixUrl: __KA_API_URL__,
    })
    .json();
}

async function fetchPasswordResetToken(token) {
  return request
    .get(`password-reset/${token}`, {
      prefixUrl: __KA_API_URL__,
    })
    .json();
}

async function register({ firstName, lastName, email, password, confirmPassword, coupon }) {
  const { token } = await request
    .post(`register`, {
      json: {
        firstName,
        lastName,
        email,
        password,
        confirmPassword,
        coupon,
      },
      prefixUrl: __KA_API_URL__,
    })
    .json();

  setToken(token);
}

async function subscribe({ email, phone }) {
  return request
    .post(`lists/sok/subscribe`, {
      json: {
        email,
        phone,
      },
    })
    .json();
}

export {
  authenticateUser,
  fetchPasswordResetToken,
  requestPasswordResetToken,
  resetPassword,
  register,
  subscribe,
};
