import { BoxedPrimaryButton } from '../components/base/Buttons';
import { Form, FormActions, FormInput, FormLabel } from '../components/base/Form';
import { H3 } from '../components/base/Headers';
import { subscribe } from '../api/users';
import { useMutation } from 'react-query';
import { useState } from 'react';
import Loader from '../components/Loader';
import SplashChrome from '../components/SplashChrome';
import styled from 'styled-components';
import useFormInput from '../hooks/useFormInput';

const SubscribeButtonContainer = styled(FormActions)`
  margin-bottom: 2rem;

  @media screen and (max-width: 1024px) {
    margin-bottom: 0;
  }
`;

const PageContainer = styled.div`
  margin-bottom: 15rem;
`;

function SubscribeButtons({ isSubmitting }) {
  if (isSubmitting) {
    return (
      <FormActions>
        <Loader />
      </FormActions>
    );
  }

  return (
    <>
      <SubscribeButtonContainer>
        <BoxedPrimaryButton>Subscribe</BoxedPrimaryButton>
      </SubscribeButtonContainer>
    </>
  );
}

function ThankYouMessage() {
  return (
    <Form>
      <FormLabel>&nbsp;</FormLabel>
      <H3>Subscribe</H3>
      <FormLabel />
      <p>Thank you for subscribing!</p>
    </Form>
  );
}

function SubscribeForm({ onSubmit, isSubmitting }) {
  const emailInput = useFormInput('');
  const nameInput = useFormInput('');
  const phoneInput = useFormInput('');

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      name: nameInput.value,
      email: emailInput.value,
      phone: phoneInput.value,
    };

    onSubmit(data);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <FormLabel>&nbsp;</FormLabel>
      <H3>Subscribe</H3>
      <FormLabel />
      <p>
        Do you want to hear the latest news and updates from Dubb? Fill out the following form and
        get connected:
      </p>
      <FormLabel htmlFor="name" required>
        <span>Name</span>
      </FormLabel>
      <FormInput id="name" maxLength="255" {...nameInput} />
      <FormLabel htmlFor="phone" required>
        <span>Phone Number</span>
      </FormLabel>
      <FormInput id="phone" maxLength="255" {...phoneInput} />
      <FormLabel htmlFor="email" required>
        <span>Email</span>
      </FormLabel>
      <FormInput id="email" maxLength="255" {...emailInput} />
      <SubscribeButtons isSubmitting={isSubmitting} />
    </Form>
  );
}

function Subscribe({ onSubmit, isSubscribed, isSubmitting }) {
  const Content = isSubscribed ? ThankYouMessage : SubscribeForm;
  const sidebar = {
    title: __KA_SITE_TITLE__,
    description: __KA_SITE_DESCRIPTION__,
  };

  return (
    <>
      <SplashChrome title="Subscribe" sidebar={sidebar}>
        <PageContainer>
          <Content onSubmit={onSubmit} isSubscribed={isSubscribed} isSubmitting={isSubmitting} />
        </PageContainer>
      </SplashChrome>
    </>
  );
}

function SubscribeScreen() {
  /* -- Hooks -- */
  const [subscribeToMailingList, { isLoading: isSubmitting }] = useMutation(subscribe);
  const [isSubscribed, setIsSubscribed] = useState(false);

  const handleSubscribe = (data) => {
    subscribeToMailingList(data, {
      onSuccess: () => {
        setIsSubscribed(true);
      },
    });
  };

  return (
    <Subscribe onSubmit={handleSubscribe} isSubscribed={isSubscribed} isSubmitting={isSubmitting} />
  );
}

export default SubscribeScreen;
