module.exports = {
  tenantId: '1vM3kynPJMIGmcDbJEgxggK9BTF',
  api: {
    root: 'https://api.hosea.io/v2/',
    membership: 'https://1rztkf5yzg.execute-api.us-east-1.amazonaws.com/prod/',
  },
  settings: {
    title: 'School of Kingdom',
    description:
      'The premier online school for developing Kingdom Reformers with sound theology, prophetic skills, and a reformational mindset.',
    helpText:
      'God has a plan and a purpose for every entity on earth as well as every individual. That means there is a Kingdom DESIGN and DESTINY for every family, school, business, nation etc. Holy Spirit is looking for those who will tune their hearts and renew their minds to receive the STRATEGIES of heaven and School Of Kingdom is here to help equip YOU to be that receiver!',
    logo: 'https://www.sokportal.com/assets/img/sok-logo.png',
    splashLogo: 'https://www.sokportal.com/assets/img/sok-logo.png',
  },
  variables: {
    colors: {
      default: `#000;`,
      'default--hover': `#e9ecef;`,
      'default-text': `#495057;`,
      danger: `hsl(0, 91%, 66%);`,
      'danger--active': `hsl(0, 100%, 38%);`,
      'danger--hover': `hsl(0, 100%, 57%);`,
      'danger-text': `#fff;`,
      neutral: `#ccc;`,
      'neutral--active': `hsl(211, 9%, 74%);`,
      'neutral--hover': `#e0e3e6;`,
      'neutral-text': `hsl(0, 0%, 13%);`,
      primary: `red;`,
      'primary--active': `rgb(33, 121, 118);`,
      'primary--disabled': `#ccc;`,
      'primary--hover': `#7fb7ba;`,
      'primary-text': `#ffffff;`,
      'primary-text--disabled': `hsl(0, 0%, 100%, .8);`,
      'primary-transparent': `rgba(156, 225, 229, 0.1);`,
      secondary: `#000;`,
      'secondary--hover': `#999;`,
      'secondary-text': `#fff;`,
      'box-shadow--1': `4px 4px 30px 0px rgba(75, 102, 171, 0.2);`,
      'bg-color': `#f7f7f7;`,
      'font-family--body': `"Montserrat", sans-serif;`,
      'font-family--header': `"Montserrat", sans-serif;`,
      header: `var(--default);`,
      'header-font': `var(--font-family--header);`,
      'header-text': `var(--default-text);`,
      'input-bg-color': `#fff;`,
      'input-border-color': `#c1ccd3;`,
      'input-color': `var(--default-text);`,
      'link-color': `#218bc3;`,
      'link-color--hover': `#165c82;`,
      'table-border': `#d3d9df;`,
      'table-hover': `#e9ecef;`,
      'table-striped': `#f6f7f8;`,
      'animate-duration': `.5s;`,
      'sidebar-header-color': `#fff;`,
    },
  },
};
