import styled from 'styled-components';

const BadgeContainer = styled.div`
  position: sticky;
  right: 0;
  bottom: 0;
  text-align: right;
  margin-top: 10rem;
`;

function KaBadge({ isSplash }) {
  return (
    <BadgeContainer isSplash={isSplash}>
      <a
        href="https://www.kingdom-architects.com/membership?AFFILIATE=SOK"
        rel="noreferrer"
        target="_blank"
      >
        <img
          src="https://creative-podcast-company-assets.s3.amazonaws.com/ka-badge.png"
          alt="Powered by KA"
          width="165"
        />
      </a>
    </BadgeContainer>
  );
}

export default KaBadge;
