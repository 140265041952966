import 'animate.css/animate.min.css';
import 'react-notifications-component/dist/theme.css';
import * as Pages from '../pages';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { hasPermission, isAuthenticated } from '../api/authenticationContext';
import { ReactQueryConfigProvider } from 'react-query';
import BootstrapProvider from '@bootstrap-styled/provider/lib/BootstrapProvider';
import GlobalStyles from './GlobalStyles';
import ReactNotification from 'react-notifications-component';
import styled from 'styled-components';

const queryConfig = {
  queries: {
    refetchOnWindowFocus: false,
    retry: 1,
  },
};

const AppContainer = styled.div`
  min-height: 100vh;
`;

function ConditionalComponent({ component: Component, condition, redirectTo }) {
  return <>{condition() ? <Component /> : <Navigate to={redirectTo} replace />}</>;
}

function AuthenticatedComponent({ component }) {
  return (
    <ConditionalComponent component={component} condition={isAuthenticated} redirectTo="/login" />
  );
}

function App() {
  return (
    <ReactQueryConfigProvider config={queryConfig}>
      <BrowserRouter>
        <GlobalStyles />
        <ReactNotification />
        <BootstrapProvider>
          <AppContainer>
            <Routes>
              <Route
                path="/my-courses"
                element={<AuthenticatedComponent component={Pages.MyCourses} />}
              />
              <Route
                path="/my-courses/library/:id"
                element={<AuthenticatedComponent component={Pages.ViewVideo} />}
              />
              <Route path="/login" element={<Pages.Login />} />
              <Route path="/subscribe" element={<Pages.Subscribe />} />
              <Route path="/about" element={<Pages.About />} />
              <Route path="/privacy" element={<Pages.Privacy />} />
              <Route path="/terms" element={<Pages.Terms />} />
              <Route path="/help" element={<Pages.Help />} />
              <Route path="/" element={<AuthenticatedComponent component={Pages.MyCourses} />} />
              <Route path="/*" element={<Pages.NotFound />} />
            </Routes>
          </AppContainer>
        </BootstrapProvider>
      </BrowserRouter>
    </ReactQueryConfigProvider>
  );
}

export default App;
