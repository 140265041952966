import { Alert, Col, Row } from '@bootstrap-styled/v4';
import { BoxedPrimaryButton } from '../components/base/Buttons';
import { CameraVideo, CheckCircle, Circle, FileEarmarkFont } from '@styled-icons/bootstrap';
import { getLibrary, markDone } from '../api/library';
import { H2 } from '../components/base/Headers';
import { markLessonComplete } from '../api/courses';
import { useMutation, useQueryCache } from 'react-query';
import { useParams } from 'react-router';
import { useState } from 'react';
import AsyncComponent from '../components/AsyncComponent';
import Chrome from '../components/Chrome';
import moment from 'moment';
import NotFound from './NotFound';
import styled from 'styled-components';
import VideoPlayer from '../components/VideoPlayer';

const TimeAgo = styled.p`
  color: var(--neutral);
  font-size: 1rem;
  font-style: italic;
`;

const Description = styled.p`
  margin-top: 1rem;
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid #333;
`;

const ResourceItem = styled.div`
  padding-top: 0.5rem;
`;

const ResourceContainer = styled.div`
  margin-top: 2rem;
`;

const SystemMessageContainer = styled.div`
  padding-top: 1rem;
`;

const CompleteIcon = styled(CheckCircle)`
  color: green;
`;

const MarkButton = styled(BoxedPrimaryButton)`
  font-size: 12px;
`;

const SectionRow = styled(Row)`
  margin-bottom: 2rem;
`;

const SectionDescription = styled.p`
  font-size: 10px;
  padding-bottom: 0.5rem;
`;

const ContentRow = styled(Row)`
  padding-bottom: 1rem;
  margin-bottom: 1rem;
`;

function SectionIcon({ isComplete }) {
  if (isComplete) {
    return <CompleteIcon size={14} />;
  }

  return <Circle size={14} />;
}

function LessonProgress({ lesson }) {
  return (
    <>
      <SectionRow>
        <Col md={2}>
          <SectionIcon isComplete={lesson.sectionsComplete} />
        </Col>
        <Col md={10}>
          <SectionDescription>
            <CameraVideo size={10} /> Video - 20 min
          </SectionDescription>
          <MarkAsDoneButton lesson={lesson} />
        </Col>
      </SectionRow>
    </>
  );
}

function MarkAsDoneButton({ lesson }) {
  const cache = useQueryCache();
  const [isUpdated, setIsUpdated] = useState(0);

  const [markAsDone, { isLoading }] = useMutation(
    () => markDone(lesson.courseId, lesson.unitId, lesson.id),
    {
      onSuccess: async () => {
        const reloadedLibrary = await getLibrary();
        cache.setQueryData('library', reloadedLibrary);
        setIsUpdated(isUpdated + 1);
      },
    },
  );

  const clickMarkAsDone = (e) => {
    e.preventDefault();
    markAsDone();
  };

  if (lesson.complete) {
    return null;
  }

  return (
    <MarkButton onClick={clickMarkAsDone} disabled={isLoading}>
      Mark Video Complete
    </MarkButton>
  );
}

function VideoContent({ lesson }) {
  const videoOptions = {
    autoplay: false,
    controls: true,
    sources: [
      {
        src: lesson.videoUrl,
        type: 'video/mp4',
      },
    ],
  };

  return <VideoPlayer {...videoOptions} />;
}

function ResourceList({ lesson }) {
  return !lesson || !lesson.links || !lesson.links.length ? null : (
    <ResourceContainer>
      <p>(mentioned links on pdf)</p>
      <h3>Resources</h3>
      {lesson.links.map((link) => {
        return (
          <ResourceItem key={link.url}>
            <FileEarmarkFont size="12" />{' '}
            <a href={link.url} target="_blank" rel="noreferrer">
              {link.text}
            </a>
          </ResourceItem>
        );
      })}
    </ResourceContainer>
  );
}

function LessonContent({ lesson }) {
  let suffix = null;
  if (lesson.done) {
    suffix = <CompleteIcon size={14} />;
  }

  if (lesson.expired) {
    return (
      <SystemMessageContainer>
        <Alert color="danger" uncontrolled>
          <p>Your access to this lesson has expired.</p>
        </Alert>
      </SystemMessageContainer>
    );
  }

  if (!lesson.isAvailable && lesson.releaseDate) {
    return (
      <SystemMessageContainer>
        <Alert color="info" uncontrolled>
          <p>This lesson premieres on {moment(lesson.releaseDate).format('MM/DD/YYYY')}.</p>
        </Alert>
      </SystemMessageContainer>
    );
  }

  return (
    <>
      <ContentRow>
        <Col md={4}>
          <H2>
            {lesson.title} {suffix}
          </H2>
          {lesson.releaseDate && <TimeAgo>{moment(lesson.releaseDate).fromNow()}</TimeAgo>}
          <Description>{lesson.description}</Description>
          <LessonProgress lesson={lesson} />
          <ResourceList lesson={lesson} />
        </Col>
        <Col md={8}>
          <VideoContent lesson={lesson} />
        </Col>
      </ContentRow>
    </>
  );
}

function ViewLesson({ lesson, refresh }) {
  return (
    <>
      <Chrome title={lesson.title}>
        <LessonContent lesson={lesson} refresh={refresh} />
      </Chrome>
    </>
  );
}

function findLesson(library, id) {
  for (let i = 0; i < library.categories.length; i++) {
    const category = library.categories[i];
    for (let j = 0; j < category?.items?.length; j++) {
      const item = category.items[j];
      if (item.id === id) {
        return item;
      }
    }
  }

  return null;
}

function ViewVideoScreen() {
  /* -- Hooks -- */
  const { id } = useParams();
  const [isUpdated, setIsUpdated] = useState(0);
  const refresh = () => {
    setIsUpdated(isUpdated + 1);
  };

  return (
    <AsyncComponent
      executeQuery={() => getLibrary()}
      cacheKey="library"
      render={(library) => {
        const lesson = findLesson(library, id);
        if (!lesson) {
          return <NotFound />;
        }

        return <ViewLesson lesson={lesson} refresh={refresh} />;
      }}
    />
  );
}

export default ViewVideoScreen;
