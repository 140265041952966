import { getLibrary } from '../api/library';
import AsyncComponent from '../components/AsyncComponent';
import Chrome from '../components/LibraryChrome';
import CourseLibrary from '../components/courses/Library';

function MyCourses({ library }) {
  return (
    <>
      <Chrome title="My Courses">
        <CourseLibrary library={library} />
      </Chrome>
    </>
  );
}

function MyCoursesScreen() {
  /* -- Hooks -- */
  return (
    <AsyncComponent
      executeQuery={getLibrary}
      cacheKey="library"
      render={(library) => <MyCourses library={library} />}
    />
  );
}

export default MyCoursesScreen;
