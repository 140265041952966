import '../fonts/flaticon.css';
import { createGlobalStyle } from 'styled-components';
import variables from '../utils/themeConfig';

function resolveGlobalVariables() {
  let globalVars = '';
  const keys = Object.keys(variables.colors);

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    globalVars += `--${key}: ${variables.colors[key]}`;
  }

  return globalVars;
}

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Flaticon';
    font-style: normal;
    font-weight: 400;
    src: local('Flaticon'),
        url('fonts/Flaticon.woff') format('woff');
  }

  :root {
   ${resolveGlobalVariables()}
  }

  *,
  *::after,
  *::before {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  a {
    color: var(--link-color);
    font-family: var(--font-family--body);
    text-decoration: none;

    :hover {
      color: var(--link-color--hover);
      text-decoration: underline;
    }
  }

  body {
    background-color: var(--bg-color);
    color: var(--default-text);
    font-family: var(--font-family--body);
    scroll-behavior: smooth;
  }

  button {
    font-family: var(--font-family--body);
  }

  html {
    font-size: 14px;
    scroll-behavior: smooth;
  }

  svg {
    fill: currentColor;
  }

  .editor-wrapper {
    width: 100% !important;
    display: block !important;
    margin-bottom: 25px !important;
    min-height: 15rem !important;
  }

  .editor {
    border: 1px solid #F1F1F1 !important;
    padding: 5px !important;
    border-radius: 2px !important;
    min-height: 15rem !important;
  }
`;

export default GlobalStyles;
