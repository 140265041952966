import { Helmet } from 'react-helmet';
import { isAuthenticated } from '../api/authenticationContext';
import { Link } from 'react-router-dom';
import buildTitle from '../utils/buildTitle';
import Footer from './Footer';
import Header from './header/Header';
import KaBadge from './KaBadge';
import localize from '../utils/localize';
import styled from 'styled-components';

const Shell = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: 'row';
  align-items: stretch;

  @media screen and (max-width: 1024px) {
    display: block;
  }
`;

const ContentCol = styled.div`
  flex: 7;

  @media screen and (max-width: 1024px) {
    padding: 2rem;
    flex: none;
    width: 100%;
  }
`;

const SidebarCol = styled.div`
  flex: 3;
  background: #000;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;

  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

const SidebarLogoWrapper = styled.div`
  padding: 2rem;
  padding-left: 3rem;
`;

const SidebarLogo = styled.img`
  width: 100px;
`;

const SidebarHeader = styled.h2`
  color: var(--sidebar-header-color);
  margin-bottom: 2rem;
  font-weight: 700;
  font-size: 40px;
  text-transform: capitalize;
`;

const Description = styled.p`
  color: var(--sidebar-header-color);
`;

const SidebarContainer = styled.div`
  padding: 2rem;
  padding-top: 5rem;
  text-align: center;
`;

const ContentContainer = styled.div`
  padding-right: 10rem;
  padding-top: 15rem;

  @media screen and (max-width: 1024px) {
    padding-top: 0;
    padding-right: 2rem;
  }
`;

const ContentWithHeaderContainer = styled.div`
  padding-right: 10rem;
  padding-top: 7.5rem;
`;

const MobileLogoContainer = styled.div`
  display: none;

  @media screen and (max-width: 1024px) {
    display: block;

    padding: 1rem;
  }
`;

function ConditionalHeader({ show, showLogo }) {
  if (!show || !isAuthenticated()) {
    return <></>;
  }

  return <Header logo={showLogo} />;
}

function SplashChrome({ children, header, headerLogo, sidebar, title }) {
  const ChildContainer = header ? ContentWithHeaderContainer : ContentContainer;
  return (
    <>
      <Shell>
        <Helmet>
          <title>{buildTitle(localize(title))}</title>
        </Helmet>
        <SidebarCol>
          <SidebarLogoWrapper>
            <Link to="/">
              <SidebarLogo src={__KA_TENANT_LOGO_URL__} />
            </Link>
          </SidebarLogoWrapper>
          <SidebarContainer>
            <SidebarHeader>{sidebar.title}</SidebarHeader>
            <Description>{sidebar.description}</Description>
            <KaBadge />
          </SidebarContainer>
        </SidebarCol>
        <MobileLogoContainer>
          <SidebarLogo src={__KA_TENANT_LOGO_URL__} />
        </MobileLogoContainer>
        <ContentCol>
          <ConditionalHeader show={header} showLogo={headerLogo} />
          <ChildContainer>{children}</ChildContainer>
          <Footer />
        </ContentCol>
      </Shell>
    </>
  );
}

SplashChrome.defaultProps = {
  headerLogo: true,
};

export default SplashChrome;
