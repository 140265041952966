import { Col, Row } from '@bootstrap-styled/v4';
import Slider from '../slider';

function Category({ category }) {
  const { items } = category;
  const sliderItems = items.map((item) => {
    return {
      id: item.id,
      image: item.thumbnailUrl,
      imageBg: item.thumbnailUrl,
      title: item.title,
      description: item.description,
    };
  });

  return (
    <>
      <Slider title={category.label}>
        {sliderItems.map((movie) => (
          <Slider.Item movie={movie} key={movie.id} />
        ))}
      </Slider>
    </>
  );
}

export default function CourseLibrary({ library }) {
  const { categories } = library;
  return (
    <div>
      {!categories?.length && (
        <Row>
          <Col md={12}>
            <p>You do not have access to any courses yet.</p>
          </Col>
        </Row>
      )}
      {/* <Category category={categories[3]} /> */}
      <>
        {categories?.length !== 0 &&
          categories?.map((category) => <Category category={category} key={category.id} />)}
      </>
    </div>
  );
}
