import { BoxedPrimaryButton } from '../components/base/Buttons';
import { Form, FormActions, FormInput, FormLabel } from '../components/base/Form';
import { H3 } from '../components/base/Headers';
import { Link, useNavigate } from 'react-router-dom';
import { register } from '../api/users';
import { useMutation } from 'react-query';
import { useState } from 'react';
import Loader from '../components/Loader';
import SplashChrome from '../components/SplashChrome';
import styled from 'styled-components';
import useFormInput from '../hooks/useFormInput';

const RegistrationActionsContainer = styled(FormActions)`
  margin-bottom: 2rem;

  @media screen and (max-width: 1024px) {
    margin-bottom: 0;

    & button {
      display: block;
      margin-bottom: 1rem;
    }
  }
`;

function RegistrationButtons({ isSubmitting }) {
  if (isSubmitting) {
    return (
      <FormActions>
        <Loader />
      </FormActions>
    );
  }

  return (
    <>
      <RegistrationActionsContainer>
        <BoxedPrimaryButton>Create My Account</BoxedPrimaryButton>
        <Link to="/login">I Already Have An Account</Link>
      </RegistrationActionsContainer>
    </>
  );
}

function RegistrationForm({ onSubmit, isSubmitting }) {
  const firstNameInput = useFormInput('');
  const lastNameInput = useFormInput('');
  const emailInput = useFormInput('');
  const passwordInput = useFormInput('');
  const confirmPasswordInput = useFormInput('');
  const [usingCoupon, setUsingCoupon] = useState(false);
  const couponInput = useFormInput('', (coupon) => {
    if (coupon && coupon.trim() !== '') {
      setUsingCoupon(true);
    } else {
      setUsingCoupon(false);
    }
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      email: emailInput.value,
      password: passwordInput.value,
      confirmPassword: confirmPasswordInput.value,
      coupon: couponInput.value,
      firstName: firstNameInput.value,
      lastName: lastNameInput.value,
    };

    onSubmit(data);
  };

  const sidebar = {
    title: __KA_SITE_TITLE__,
    description: __KA_SITE_DESCRIPTION__,
  };

  let additionalFields = null;
  if (usingCoupon) {
    additionalFields = (
      <>
        <FormLabel htmlFor="firstName" required>
          <span>First Name</span>
        </FormLabel>
        <FormInput id="firstName" maxLength="255" {...firstNameInput} />
        <FormLabel htmlFor="lastName" required>
          <span>Last Name</span>
        </FormLabel>
        <FormInput id="lastName" maxLength="255" {...lastNameInput} />
      </>
    );
  }

  return (
    <>
      <SplashChrome title="Register" sidebar={sidebar}>
        <Form onSubmit={handleSubmit}>
          <FormLabel>&nbsp;</FormLabel>
          <H3>Create An Account</H3>
          <FormLabel htmlFor="email" required>
            <span>Email</span>
          </FormLabel>
          <FormInput id="email" maxLength="255" {...emailInput} />

          <FormLabel htmlFor="password" required>
            <span>Password</span>
          </FormLabel>
          <FormInput
            id="password"
            type="password"
            autoComplete="new-password"
            maxLength="255"
            {...passwordInput}
          />
          <FormLabel htmlFor="confirmPassword" required>
            <span>Confirm Password</span>
          </FormLabel>
          <FormInput
            id="confirmPassword"
            type="password"
            autoComplete="new-password"
            maxLength="255"
            {...confirmPasswordInput}
          />

          <FormLabel htmlFor="coupon" required>
            <span>Coupon (optional)</span>
          </FormLabel>
          <FormInput id="coupon" maxLength="255" {...couponInput} />
          {additionalFields}
          <RegistrationButtons isSubmitting={isSubmitting} />
        </Form>
      </SplashChrome>
    </>
  );
}

function RegistrationScreen() {
  /* -- Hooks -- */
  const navigate = useNavigate();
  const [createAccount, { isLoading: isSubmitting }] = useMutation(register);

  const handleRegister = (data) => {
    createAccount(data, {
      onSuccess: () => {
        navigate(`/`, { replace: true });
      },
    });
  };

  return <RegistrationForm onSubmit={handleRegister} isSubmitting={isSubmitting} />;
}

export default RegistrationScreen;
