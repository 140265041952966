import request from './request';

async function getLibrary() {
  return request.get('course-library/list').json();
}

async function markDone(courseId, unitId, lessonId) {
  return request.put(
    `course-library/courses/${courseId}/units/${unitId}/lessons/${lessonId}/progress`,
    {
      json: {},
    },
  );
}

export { getLibrary, markDone };
